import { gql, TypedDocumentNode } from '@apollo/client';

export type GetShoppableEditsQuery = {
  shoppableEdits: {
    __typename: 'RestShoppableEditsResponse';
    data: ShoppableEdit[];
    meta: {
      pagination: Pagination;
    };
  };
};

export type ShoppableEdit = {
  id: number;
  name: string;
  items: [
    {
      color: string;
      id: number;
      productId: number;
      quantity: number;
      size: string;
      skuId: number;
      status: string;
      trunkshowId: number;
      userId: number;
      variantId: number;
    }
  ];
  userId: number;
  shared: boolean;
  status: string;
  stylistName: string;
};

type Pagination = {
  currentPage: number;
  totalEntries: number;
  previousPage: number | null;
  nextPage: number | null;
  perPage: number;
  totalPages: number;
};

export type GetShoppableEditsVariables = {
  page?: number;
  perPage?: number;
};

const PAGINATION_FRAGMENT = gql`
  fragment ShoppableEditsPaginationFragment on RestShoppableEditsMeta {
    pagination @type(name: "RestShoppableEditsPagination") {
      currentPage: current_page
      totalEntries: total_entries
      previousPage: previous_page
      nextPage: next_page
      perPage: per_page
      totalPages: total_pages
    }
  }
`;

export const GET_SHOPPABLE_EDITS_QUERY: TypedDocumentNode<
  GetShoppableEditsQuery,
  GetShoppableEditsVariables
> = gql`
  query GetShoppableEditsQuery($page: Int, $perPage: Int) {
    shoppableEdits(page: $page, perPage: $perPage)
      @rest(
        type: "RestShoppableEditsResponse"
        endpoint: "moda"
        path: "/v3.5/shoppable_edits?page={args.page}&per_page={args.perPage}&shared=true"
        method: "GET"
      ) {
      data @type(name: "RestShoppableEdits") {
        id
        name
        items {
          color
          id
          productId: product_id
          quantity
          size
          skuId: sku_id
          status
          trunkshowId: trunkshow_id
          userId: user_id
          variantId: variant_id
        }
        userId: user_id
        shared
        status
        stylistName: stylist_name
      }
      meta @type(name: "RestShoppableEditsMeta") {
        ...ShoppableEditsPaginationFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
`;
