import { useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { GET_SHOPPABLE_EDITS_QUERY } from './shoppableEdits.rest';

const DEFAULT_PER_PAGE = 10;

export const useShoppableEdits = () => {
  const {
    data,
    loading,
    error,
    fetchMore: fetchMoreFn
  } = useQuery(GET_SHOPPABLE_EDITS_QUERY, {
    variables: {
      page: 1,
      perPage: DEFAULT_PER_PAGE
    },
    notifyOnNetworkStatusChange: true
  });

  const fetchMore = useCallback(
    (page: number) => {
      fetchMoreFn({
        variables: {
          page,
          perPage: DEFAULT_PER_PAGE
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          return {
            shoppableEdits: {
              __typename: 'RestShoppableEditsResponse',
              data: [...prev.shoppableEdits.data, ...fetchMoreResult.shoppableEdits.data],
              meta: fetchMoreResult.shoppableEdits.meta
            }
          };
        }
      });
    },
    [fetchMoreFn]
  );

  return {
    edits: data?.shoppableEdits?.data ?? [],
    pagination: data?.shoppableEdits?.meta?.pagination,
    loading,
    error,
    fetchMore
  };
};
